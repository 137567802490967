<template>
  <div>
    <div v-if="isMounted" class="vx-row no-gutter items-center justify-center">
      <div v-if="applicant.success == true" class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-1/3 m-0">
        <vx-card class="login-card py-3 px-3">
          <div slot="no-body" class="">
            <div class="vx-col sm:w-full">
              <div class="px-8 pt-8">
                <div class="vx-card__title mb-4">
                  <h4 class="mb-1">
                    Tarjetas registradas
                  </h4>
                  <p><small><strong>*Tus datos se manejan de forma segura con encriptación de 256 bits.</strong></small></p>
                </div>
                <div class="vx-row">
                  <div :class="colClass">
                    <vs-input
                      class="w-full"
                      label="Nombre o Razón Social"
                      name="alias"
                      readonly="true"
                      v-model.lazy="applicant.name"
                      icon-pack="feather"
                    />
                  </div>
                  <div :class="colClass">
                    <vs-input
                      class="w-full"
                      label="Correo electrónico"
                      name="email"
                      readonly="true"
                      v-model.lazy="applicant.email"
                      icon-pack="feather"
                    />
                  </div>
                </div>
                <div class="vx-row mb-base">
                <div class="vx-col w-full">
                    <vs-table stripe class="mt-1" ref="cardsTable" :data="formattedCards" noDataText="No hay tarjetas disponibles">
                        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between mb-5">
                            <h5>Administra tus tarjetas.</h5>
                            <div class="flex flex-wrap-reverse items-center data-list-btn-container">
                                <vs-button icon-pack="feather" radius icon="icon-plus" @click="showNewCardPopup(tr)"></vs-button>
                            </div>
                        </div>                       
                        <template slot="thead">
                        <vs-th class="bold">Tarjeta</vs-th>
                        <vs-th class="bold">Banco</vs-th>
                        <vs-th class="bold">Acciones</vs-th>
                        </template>

                        <template slot-scope="{data}">
                        <tbody>
                        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td>
                            <div class="flex items-center">
                                <img :src="cardImgSource(tr.thumb)" alt="card" class="card-thumbnail">
                                <p class="font-large pl-4">
                                Terminación <span class="bold">{{ tr.number }}</span>
                                </p>
                            </div>
                            </vs-td>
                            <vs-td class="calif-container">
                            <p class="font-medium">{{tr.bank}}</p>
                            </vs-td>
                            <vs-td>
                                <div class="flex items-center">
                                    <vs-button icon-pack="feather" radius icon="icon-edit" @click="onCardSelected(tr)"></vs-button>
                                    <vs-button class="ml-3" :disabled="formattedCards.length <= 1" icon-pack="feather" radius type="border" icon="icon-trash" @click="deleteCardRequest(tr)"></vs-button>
                                </div>
                            </vs-td>
                        </vs-tr>
                        </tbody>
                        </template>
                    </vs-table>
                </div>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
      <div v-else class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-1/3 m-0">
        <vx-card style="padding: 5rem;" class="text-center w-full">
            <img src="@/assets/images/figs/fig9.png" alt="graphic-404" class="mx-auto mb-4 max-w-full">
            <h2 class="mb-6 mt-6">Verifica tus datos</h2>
            <vs-button @click="goToHomePage()">Volver al inicio</vs-button>
        </vx-card>
      </div>

    <vs-popup :active.sync="isNewCardPopupActive" title="Agregar nueva tarjeta">
      <register-card
        v-if="applicant.userId"
        :user-id="applicant.userId"
        :accepts="['debit']"
        :save-logs="false"
        @on-card-saved="onCardSaved"/>
    </vs-popup>

    <vs-popup :active.sync="isCardCvvPopupActive" title="Confirmar código de seguridad">
      <update-card-cvv
        v-if="applicant.userId"
        :user-id="applicant.userId"
        :card-id="preselectedCardId"
        :card-description="preselectedCardDescription"
        @on-cvv-updated="onCardCvvUpdated" />
    </vs-popup>

    </div>
  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import cardsHelper from "@mixins/cardsHelper";
import openpayErrors from "@mixins/openpayErrors";
import RegisterCard from "@components/shared/cards/RegisterCard";
import UpdateCardCvv from "@components/shared/cards/UpdateCardCvv";
export default {
  name: "ListCardsPage",
  mixins: [inputHelper,cardsHelper,openpayErrors],
  components: {
    RegisterCard,
    UpdateCardCvv
  },
  props: ["external_id"],
  data(){
    return {
      isMounted: false,
      colClass: "vx-col w-full mb-5",
      card_selected: '',
      cardIdSelected: null,
      cardRowSelected: null,
      preselectedCardId: null,
      preselectedCardDescription: null,
      card: {
        card_number: null,
        cvv: null,
        holder_name: null,
        expiration: null,
      },
      deviceSessionId: null,
      defaultCardBrand: 'unknown',
      dataCardBrand: 'unknown',
      onError: false,
      errorMssg: null,
      wasSaved: false,
      expirationMonth: null,
      expirationYear: null,
      numericCard: null,
      formattedCards: [],
      acceptsOnlyDebit: false,
      applicant: {
          userId: null,
          success: false,
          name:null,
          email:null,
          cards:[]
      }
    }
  },
  async mounted(){
    this.isMounted = false;
    this.showLoading(true);
    await this.getApplicantCards();
    this.showLoading(false);
    this.isMounted = true;
  },
  computed:{
    isNewCardPopupActive: {
      get() {
        return this.$store.state.isNewCardPopupActive;
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_NEW_CARD_POPUP_ACTIVE", val);
      }
    },
    isCardCvvPopupActive: {
      get() {
        return this.$store.state.isCardCvvPopupActive;
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_CARD_CVV_POPUP_ACTIVE", val);
      }
    }
  },
  methods: {
    async getApplicantCards(){
      try {
        const res = await this.publicApiGet(`api/v1/applicant/${this.external_id}/getCards`);
        this.applicant = res.data;
        let cards = res.data.cards;
        let fcards = [];
        cards.forEach(f => {
          let card = f.card;
          let ct = this.getCardTermination(card.card_number);
          fcards.push({
            id: card.id,
            number: ct,
            brand: this.cardBrandName(card.card_brand),
            thumb: this.cardThumbail(card.card_brand),
            typeFormat: this.cardTypeFormat(card.card_type),
            type: card.card_type,
            bank: card.bank_name,
            expirationStatus: f.expiration_status,
            selected: false});
        });
        this.formattedCards = fcards;
      }
      catch (e){
        this.showLoading(false)
        // await this.$router.replace({name: 'login'});
        this.warn(e);
      }
    },
    showNewCardPopup(){
      this.$store.commit('TOGGLE_IS_NEW_CARD_POPUP_ACTIVE', true)
    },
    getCardTermination(card){
      if(card && card.length && card.length >= 4){
        return card.substr(card.length - 4);
      }
      return  "";
    },
    cardImgSource(thumb){
      return require(`@assets/images/cards/${thumb}.png`);
    },
    isCardSelected(tr){
      return tr.id === this.cardIdSelected;
    },
    isExpired(tr){
      return tr.expirationStatus === 'expired'
    },
    clean(){
      this.onError = false
      this.errorMssg = null
    },
    deleteCardRequest(tr){
      this.cardIdToDelete = tr.id;
      // se quiere eliminar la tarjeta elegida
      let mssg = `¿Realmente deseas eliminar la tarjeta con terminación ${tr.number}?`;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Eliminar tarjeta',
        text: mssg,
        acceptText: "Eliminar",
        cancelText: 'Cancelar',
        accept: this.doDeleteCard
      });

    },
    async doDeleteCard(){
      this.showLoading(true)
      try {
        let data = {
          user_id: this.applicant.userId,
          card_id: this.cardIdToDelete,
          force_delete: true,
        }
        const response = await axios.post('/api/user/cards/delete', data);
        if(this.cardIdSelected === this.cardIdToDelete){
          this.cardIdSelected = null;
          this.cardRowSelected = null;
        }
        await this.getApplicantCards();
        this.deleteSuccessNotif();
      }
      catch (error){
        let mssg = "Ha ocurrido un error con la operacion, por favor intenta más tarde.";
        if(error.response && error.response.data && error.response.data.message){
          mssg = error.response.data.message;
        }
        this.errorNotifDialog("Operación no completada", mssg);
      }
      this.showLoading(false)
    },
    async onCardSaved(id){
      this.createdCardId = id;
      this.showLoading(true)
      await this.getApplicantCards();
      const card = this.formattedCards.find(f => f.id === id);
      if(!this.isSelectable(card)){
        this.cardRowSelected = null;
        this.cardIdSelected = null;
        this.errorNotif('Tarjeta no elegible', 'Sólo se permiten tarjetas de débito para esta acción.');
      }
      else {
          //this.successNotif('Operación exitosa', 'Los datos han sido actualizados de manera exitosa.');
        //   this.cardIdSelected = id;
        //   this.cardRowSelected = card;
      }
      this.showLoading(false);
    //   this.$store.commit('TOGGLE_IS_NEW_CARD_POPUP_ACTIVE', false);
    },
    async onCardCvvUpdated(id){
    //   this.cardIdSelected = this.preselectedCardId;
      this.successNotif('Operación exitosa', 'Los datos han sido actualizados de manera exitosa.');
      //this.cardRowSelected = this.formattedCards.find(f => f.id === id);
      this.preselectedCardId = null;
      this.preselectedCardDescription = null;
    },
    isSelectable(tr){
      if(this.acceptsOnlyDebit){
        return this.isDebitCard(tr.type);
      }
      return true;
    },
    onCardSelected(tr){
        this.cardRowSelected = null;
        let card = this.formattedCards.find(f => f.id === tr.id);
        this.preselectedCardDescription = `${card.brand} - ${card.typeFormat} ${card.bank}, terminación ${card.number}`;
        this.preselectedCardId = tr.id;
        this.$store.commit('TOGGLE_IS_CARD_CVV_POPUP_ACTIVE', true)
    },
    validateNewCard(){
        this.errorMssg = null;
        if (this.newCardComplete) {
            this.card.device= this.deviceSessionId;
            this.card.user_id=this.applicant.user_id;
            this.saveNewCard();
        }else{
          this.errorMssg = "Debes completar todos los campos.";
        }
    },
    async goToHomePage(){
        await this.$router.push({name: 'login'});
    },
  }
}
</script>