<template>
  <div id="update-cvv-card-form">
    <h6>Ingrese por favor el código de seguridad de tu tarjeta</h6>
    <p><small>Tus datos se manejan de forma segura con encriptación de 256 bits.</small></p>
    <vs-alert v-if="onError" color="danger" class="mt-3 mb-3" style="height: auto">{{ errorMssg }}</vs-alert>

    <div class="vx-row mt-4">
      <div class="vx-col w-full">
        <p><span class="bold">Tarjeta: </span> {{cardDescription}} </p>
      </div>
    </div>

    <div class="vx-row mt-4">
      <div class="vx-col w-5/12">
        <label class="vs-input--label bold">Código de seguridad</label>
        <vs-input
          class="w-full mt-1"
          name="cvv"
          v-validate="requiredRules"
          danger-text="Campo requerido"
          :danger="cvvError"
          v-cardformat:formatCardCVC
          v-model.lazy="card.cvv"
          icon-pack="feather" />
      </div>
    </div>

    <div class="vx-row mt-2">
    </div>

    <div class="vx-row">
      <div class="vx-col w-full mt-3">
        <vs-button type="border" class="ml-auto mt-2" @click="onUpdateCard">Guardar</vs-button>
        <vs-button type="border" class="mr-auto mt-2 ml-6" color="dark" @click="cancel">Cancelar</vs-button>
      </div>
    </div>

  </div>
</template>

<script>
import cardsHelper from "@mixins/cardsHelper";
import openpayErrors from "@mixins/openpayErrors";
export default {
  name: "UpdateCardCvv",
  mixins: [cardsHelper, openpayErrors],
  props: {
    userId: {
      type: [String,Number],
      required: true
    },
    cardId: {
      type: [String,Number],
    },
    cardDescription: {
      type: [String],
    },
  },
  data() {
    return {
      debitType: 'debit',
      creditType: 'credit',
      requiredRules: 'required',
      colClass: "vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full mb-5",
      defaultCardBrand: 'unknown',
      dataCardBrand: 'unknown',
      deviceSessionId: null,
      onError: false,
      errorMssg: null,
      wasSaved: false,
      card: {
        card_number: null,
        cvv: null,
        holder_name: null,
        expiration: null,
      },
      expirationMonth: null,
      expirationYear: null,
      numericCard: null,
    }
  },
  computed: {
    cardNumberError(){
      return this.hasError('card_number');
    },
    holderNameError(){
      return this.hasError('holder_name');
    },
    expirationError(){
      return this.hasError('expiration');
    },
    cvvError(){
      return this.hasError('cvv');
    },
  },
  async mounted(){
  },
  methods: {
    async onUpdateCard(){
      this.onError = false;
      const valid = await this.$validator.validateAll();
      if(!valid){
        this.$emit('on-fields-validated', false);
        return;
      }

      this.showLoading(true)
      try {
        let data = {
          user_id: this.userId,
          card_id: this.cardId,
          cvv: this.card.cvv,
        }
        const response = await axios.post('/api/user/cards/cvv', data)
        this.wasSaved = true;
        this.$emit('on-cvv-updated', this.cardId);
        await this.finish();
      }
      catch (e) {
        this.errorMssg = e.response.data.message;
        this.onError = true;
      }
      this.showLoading(false)
    },
    async cancel(){
      this.$store.commit("TOGGLE_IS_CARD_CVV_POPUP_ACTIVE", false);
      this.resetForm();
    },
    async finish(){
      this.$store.commit("TOGGLE_IS_CARD_CVV_POPUP_ACTIVE", false);
      this.resetForm();
    },
    resetForm(){
      this.errorMssg = null;
      this.wasSaved = false;
      this.onError = false;
      this.card = {};
    },
    hasError(val){
      return this.errors.has(val);
    },
  }
}
</script>

<style scoped>

</style>