export default {
  methods: {
    getMessageFromErrorCode(code) {
      switch (code) {
        case 1000:
          return 'Ocurrió un error interno en el servidor de Openpay';
        case 1001:
          return 'Los datos cargados son inválidos.';
        case 1002:
        case 1003:
        case 1010:
          return 'Ha ocurrido un error con la petición, contacte con soporte.';
        case 1004:
          return 'Un servicio necesario para el procesamiento de la transacción no se encuentra disponible.';
        case 1005:
          return 'Uno de los recursos requeridos no existe.';
        case 1006:
          return 'Ya existe una transacción con el mismo ID de orden.';
        case 1007:
          return 'La transferencia de fondos entre una cuenta de banco o tarjeta y la cuenta de Openpay no fue aceptada.';
        case 1008:
          return 'Una de las cuentas requeridas en la petición se encuentra desactivada.';
        case 1009:
          return 'El cuerpo de la petición es demasiado grande.';
        case 2001:
          return 'La cuenta de banco con esta CLABE ya se encuentra registrada en el cliente.';
        case 2002:
          return 'La tarjeta con este número ya se encuentra registrada en el cliente.';
        case 2003:
          return 'El cliente con este identificador ya existe.';
        case 2004:
          return 'El dígito verificador del número de tarjeta es inválido.';
        case 2005:
          return 'La fecha de expiración de la tarjeta es anterior a la fecha actual.';
        case 2006:
          return 'El código de seguridad de la tarjeta (CVV2) no fue proporcionado';
        case 2007:
          return 'El número de tarjeta no es válido, solamente puede usarse en pruebas.';
        case 2008:
          return 'La tarjeta consultada no es válida para puntos.';
        case 2009:
          return 'El código de seguridad de la tarjeta (CVV2) no es válido.';
        case 3001:
          return 'La tarjeta fue declinada.';
        case 3002:
          return 'La tarjeta ha expirado.';
        case 3003:
          return 'La tarjeta no tiene fondos suficientes.';
        case 3004:
          return 'La tarjeta ha sido identificada como una tarjeta robada.';
        case 3005:
          return 'La tarjeta ha sido identificada como una tarjeta fraudulenta.';
        case 3006:
          return 'La operación no esta permitida para este cliente o esta transacción.';
        case 3008:
          return 'La tarjeta no es soportada en transacciones en linea.';
        case 3009:
          return 'La tarjeta fue reportada como perdida.';
        case 3010:
          return 'El banco ha restringido la tarjeta.';
        case 3011:
          return 'El banco ha solicitado que la tarjeta sea retenida. Contacte al banco.';
        case 3012:
          return 'Se requiere solicitar al banco autorización para realizar este pago.';
        default:
          return 'Ha ocurrido un error desconocido, intente más tarde';
      }
    },
  }
}