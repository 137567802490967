<template>
  <div id="register-card-form">
    <h6>Ingrese los datos de la tarjeta</h6>
    <p><small>Tus datos se manejan de forma segura con encriptación de 256 bits.</small></p>
    <vs-alert v-if="onError" color="danger" class="mt-3 mb-3" style="height: auto">{{ errorMssg }}</vs-alert>
    <vs-alert v-if="wasSaved" color="success" class="mt-3 mb-3" style="height: auto">La tarjeta ha sido registrada exitosamente.</vs-alert>
    <div class="vx-row mt-4">
      <div class="vx-col w-full mb-3">
        <label class="vs-input--label bold">Número de tarjeta</label>
        <vx-input-group class="mt-1">
          <template slot="prepend">
            <div class="prepend-text px-1 bg-card" style="height: 40px" >
              <img :src="cardImgSource()" alt="card" class="card-thumbnail p-1">
            </div>
          </template>

          <vs-input
            class="w-full"
            name="card_number"
            v-cardformat:formatCardNumber
            ref="card"
            v-validate="requiredRules"
            v-model.lazy="card.card_number"
            :danger="cardNumberError"
            danger-text="El campo es requerido"
            v-on:input="onCardChanged"
            icon-pack="feather" />
        </vx-input-group>
      </div>
    </div>

    <div class="vx-row mt-2">
      <div class="vx-col w-full mb-3">
        <label class="vs-input--label bold">Nombre del titular</label>
        <vs-input
          class="w-full mt-1"
          name="holder_name"
          v-validate="requiredRules"
          danger-text="El campo es requerido"
          :danger="holderNameError"
          v-model.lazy="card.holder_name"
          icon-pack="feather" />
      </div>
    </div>

    <div class="vx-row mt-2">
      <div class="vx-col w-full mb-3">
        <div class="vx-row">
          <div class="vx-col w-7/12">
            <label class="vs-input--label bold">Fecha de expiración <small>MM/YYYY</small></label>
            <vs-input
              class="w-full mt-1"
              name="expiration"
              v-validate="requiredRules"
              danger-text="Campo requerido"
              :danger="expirationError"
              v-cardformat:formatCardExpiry
              v-model.lazy="card.expiration"
              icon-pack="feather" />
          </div>
          <div class="vx-col w-5/12">
            <label class="vs-input--label bold">Código de seguridad</label>
            <vs-input
              class="w-full mt-1"
              name="cvv"
              v-validate="requiredRules"
              danger-text="Campo requerido"
              :danger="cvvError"
              v-cardformat:formatCardCVC
              v-model.lazy="card.cvv"
              icon-pack="feather" />
          </div>
        </div>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full mt-3">
        <vs-button v-if="!wasSaved" type="border" class="ml-auto mt-2" @click="onSaveCard">Guardar</vs-button>
        <vs-button v-if="!wasSaved" type="border" class="mr-auto mt-2 ml-6" color="dark" @click="cancel">Cancelar</vs-button>
        <vs-button v-if="wasSaved" type="border" class="mr-auto mt-2" color="success" @click="finish">Finalizar</vs-button>
      </div>
    </div>

  </div>
</template>

<script>
import cardsHelper from "@mixins/cardsHelper";
import openpayErrors from "@mixins/openpayErrors";
export default {
  name: "RegisterCard",
  mixins: [cardsHelper, openpayErrors],
  props: {
    userId: {
      type: [String,Number],
      required: true
    },
    accepts: {
      type: Array,
      required: true,
      default: ['debit']
    },
    saveLogs: {
      type: [Boolean],
      required: true,
      default: false
    },
  },
  data() {
    return {
      debitType: 'debit',
      creditType: 'credit',
      requiredRules: 'required',
      colClass: "vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full mb-5",
      defaultCardBrand: 'unknown',
      dataCardBrand: 'unknown',
      deviceSessionId: null,
      onError: false,
      errorMssg: null,
      wasSaved: false,
      card: {
        card_number: null,
        cvv: null,
        holder_name: null,
        expiration: null,
      },
      expirationMonth: null,
      expirationYear: null,
      numericCard: null,
    }
  },
  computed: {
    cardNumberError(){
      return this.hasError('card_number');
    },
    holderNameError(){
      return this.hasError('holder_name');
    },
    expirationError(){
      return this.hasError('expiration');
    },
    cvvError(){
      return this.hasError('cvv');
    },
  },
  async mounted(){
    this.setOpenpay();
  },
  methods: {
    setOpenpay(){
      let op_public = process.env.VUE_APP_OPENPAY_PUBLIC;
      let op_data = process.env.VUE_APP_OPENPAY_ID;
      let op_production = process.env.VUE_APP_OPENPAY_ENV !== 'local';
      OpenPay.setId(op_data);
      OpenPay.setApiKey(op_public);
      OpenPay.setSandboxMode(!op_production);
      this.deviceSessionId = OpenPay.deviceData.setup("register-card-form", "deviceIdHiddenFieldName");
    },
    onCardChanged(){
      if(this.$refs.card && this.$refs.card.$refs.vsinput){
        let attr = this.$refs.card.$refs.vsinput.attributes.getNamedItem('data-card-brand');
        if(attr != null){
          this.dataCardBrand = attr.value;
        }
        else {
          this.dataCardBrand = this.defaultCardBrand;
        }
      }
    },
    cardImgSource(){
      let thumb = this.cardThumbail(this.dataCardBrand);
      return require(`@assets/images/cards/${thumb}.png`);
    },
    async onSaveCard(){
      this.onError = false;
      const valid = await this.$validator.validateAll();
      if(!valid){
        this.$emit('on-fields-validated', false);
        return;
      }
      this.showLoading(true)
      this.setExpiration();
      let ss = this.card.card_number;
      this.numericCard = ss.replace(/\s/g, '');
      const openpayObject = {
        card_number: this.numericCard,
        holder_name: this.card.holder_name,
        cvv2: this.card.cvv,
        expiration_year: this.expirationYear,
        expiration_month: this.expirationMonth,
      }

      OpenPay.token.create(openpayObject, this.cardRegistered, this.cardFailed);
    },
    async cardRegistered(response){
      let payload = {
        user_id: this.userId,
        device: this.deviceSessionId,
        card_name: this.card.holder_name,
        num_card: this.numericCard,
        cvv: this.card.cvv,
        month: this.expirationMonth,
        year: this.expirationYear
      }
      try {
        if (this.saveLogs) {
          this.injectAccountMetadataToPayload(payload);
        }
        const res = await axios.post('/api/user/cards/save', payload);
        this.wasSaved = true;
        this.$emit('on-card-saved', res.data.id);
      }
      catch (e) {
        if(e.response && e.response.data){
          this.errorMssg = e.response.data.message ? e.response.data.message : e.response.data;
        }
        else {
          this.errorMssg = "Ha ocurrido un error con la operación, intente más tarde.";
        }
        this.onError = true;
      }
      this.showLoading(false)
    },
    async cardFailed(response){
      this.warn(response);
      if(response && response.data){
        this.errorMssg = this.getMessageFromErrorCode(response.data.error_code);
        this.onError = true;
      }
      this.showLoading(false)
    },
    setExpiration(){
      this.expirationMonth = "";
      this.expirationYear = "";
      const splits = this.card.expiration.split("/");
      if(splits.length > 1){
        this.expirationMonth = splits[0].trim();
        let year = splits[1].trim();
        if(year && year.length && year.length >= 2){
          this.expirationYear = year.substr(year.length - 2);
        }
      }
    },
    async cancel(){
      this.$store.commit("TOGGLE_IS_NEW_CARD_POPUP_ACTIVE", false);
      this.resetForm();
    },
    async finish(){
      this.$store.commit("TOGGLE_IS_NEW_CARD_POPUP_ACTIVE", false);
      this.resetForm();
    },
    resetForm(){
      this.errorMssg = null;
      this.wasSaved = false;
      this.onError = false;
      this.card = {};
      this.expirationYear = null;
      this.expirationMonth = null;
      this.numericCard = null;
      this.dataCardBrand = this.defaultCardBrand;
    },
    hasError(val){
      return this.errors.has(val);
    },
  }
}
</script>

<style scoped>

</style>