export default {
  methods: {
    cardBrandName(brand) {
      let mbrand = brand.toLowerCase().replace(/\s/g, '');
      switch (mbrand) {
        case 'visa':
        case 'visaretired':
          return 'Visa';
        case 'mastercard':
          return 'Master Card';
        case 'americanexpress':
        case 'american_express':
        case 'amex':
          return 'American Express';
        case 'discovercard':
          return 'Discover Card'
        default:
          return  'Tarjeta';
      }
    },
    cardTypeFormat(type){
      if(type === 'debit' || type === 'Débito'){
        return 'Débito';
      }
      else if(type === 'credit' || type === 'Crédito'){
        return 'Crédito';
      }
    },
    isDebitCard(type){
      return type === 'debit';
    },
    isCreditCard(type){
      return type === 'credit';
    },
    cardThumbail(brand){
      let mbrand = brand.toLowerCase().replace(/\s/g, '');
      switch (mbrand) {
        case 'visa':
        case 'visaretired':
          return 'card-visa';
        case 'mastercard':
          return 'card-master';
        case 'americanexpress':
        case 'american_express':
        case 'amex':
          return 'card-amex';
        case 'discovercard':
        case 'discover':
          return 'card-discover'
        default:
          return 'card-generic';
      }
    }
  }
}